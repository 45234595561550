import store from '@/store'

const adminguard = (to, from, next) => {
    if(localStorage.getItem('smsadvantage_client' + store.getters.authuser.id) != null && store.getters.authuser != null && store.getters.authuser.name ){
        return next()
    }else{
        return next({ name: 'login' })
    }
};



let routes = [
    { 
        path: "/",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/main.vue"),
        title:'',

          children: [

            {
              path: "",
              name: "welcome",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/dash.vue"),
              meta: {
              title: 'Manage Ongoing Orders and Create New ones'
              }
            },

            {
              path: "analytics",
              name: "analytics",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/analytics/analytics.vue"),
              meta: {
              title: 'System Data Analytics'
              }
            },

            {
              path: "/industries",
              name: "",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/Industries/main.vue"),
              children: [
                {
                  path: "",
                  name: "industries",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/Industries/industries.vue"),
                  meta: {
                  title: 'Manage Ongoing Orders and Create New ones'
                  }
                },
                {
                  path: "proffessions",
                  name: "proffessions",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/Industries/proffessions.vue"),
                  meta: {
                  title: 'Manage Ongoing Orders and Create New ones'
                  }
                },
                
              ]
            },

            {
              path: "contacts",
              name: "",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/contacts/main.vue"),
              children: [

                {
                  path: "",
                  name: "manage_contacts",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/contacts/contact.vue"),
                }
              ]
              
            },


            {
              path: "careers",
              name: "",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/careers/main.vue"),
              children: [

                {
                  path: "",
                  name: "manage_careers",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/careers/dash.vue"),
                },

                {
                  path: "industries",
                  name: "manage_industries",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/careers/industries.vue"),
                },

             

                {
                  path: "industries/proffesions/:id/:title",
                  name: "manage_industry_professions",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/careers/professions.vue"),
                },


                {
                  path: "professions",
                  name: "manage_professions",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/careers/professions.vue"),
                }
              ]
              
            },


            {
              path: "locations",
              name: "",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/locations/main.vue"),
              children: [
                {
                  path: "dashboard",
                  name: "locations_dash",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/locations/dash.vue"),
                  meta: {
                    title: 'Manage Ongoing Orders and Create New ones'
                    }
                },

                {
                  path: "dashboard",
                  name: "",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/locations/countries/main.vue"),
                    children:[
                      {
                        path: "countries",
                        name: "country_list",
                        props:true,
                        beforeEnter: adminguard,
                        component: () => import("@/views/app/locations/countries/country_list.vue"),
                        meta: {
                          title: ',Manage Countries in the Database'
                          }
                      },
                      {
                        path: "countries/:id",
                        name: "country_details",
                        props:true,
                        beforeEnter: adminguard,
                        component: () => import("@/views/app/locations/countries/country_details.vue"),
                        meta: {
                          title: ',Manage Countries in the Database'
                          }
                      },

                    ]
                },

                {
                  path: "dashboard",
                  name: "",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/locations/countries/main.vue"),
                    children:[
                      {
                        path: "regions",
                        name: "regions_list",
                        props:true,
                        beforeEnter: adminguard,
                        component: () => import("@/views/app/locations/regions/regions_list.vue"),
                        meta: {
                          title: 'Manage Countries in the Database'
                          }
                      },
                      {
                        path: "country/regions/:id/:title",
                        name: "regions_list_bycountry",
                        props:true,
                        beforeEnter: adminguard,
                        component: () => import("@/views/app/locations/regions/regions_list.vue"),
                        meta: {
                          title: 'Manage Countries in the Database'
                          }
                      },
                      {
                        path: "regions/:id",
                        name: "region_details",
                        props:true,
                        beforeEnter: adminguard,
                        component: () => import("@/views/app/locations/regions/region_details.vue"),
                        meta: {
                          title: 'Manage Regions in the Database'
                          }
                      },

                    ]
                },


                {
                  path: "dashboard",
                  name: "",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/locations/countries/main.vue"),
                    children:[
                      {
                        path: "regions",
                        name: "regions_list",
                        props:true,
                        beforeEnter: adminguard,
                        component: () => import("@/views/app/locations/regions/regions_list.vue"),
                        meta: {
                          title: 'Manage Countries in the Database'
                          }
                      },
                      {
                        path: "country/regions/:id/:title",
                        name: "regions_list_bycountry",
                        props:true,
                        beforeEnter: adminguard,
                        component: () => import("@/views/app/locations/regions/regions_list.vue"),
                        meta: {
                          title: 'Manage Countries in the Database'
                          }
                      },
                      {
                        path: "regions/:id",
                        name: "region_details",
                        props:true,
                        beforeEnter: adminguard,
                        component: () => import("@/views/app/locations/regions/region_details.vue"),
                        meta: {
                          title: 'Manage Regions in the Database'
                          }
                      },

                    ]
                },

              

                {
                  path: "dashboard",
                  name: "",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/locations/countries/main.vue"),
                    children:[
                      {
                        path: "districts",
                        name: "districts_list",
                        props:true,
                        beforeEnter: adminguard,
                        component: () => import("@/views/app/locations/districts/districts_list.vue"),
                        meta: {
                          title: 'Manage Countries in the Database'
                          }
                      },
                      {
                        path: "region/districts/:id/:title",
                        name: "districts_list_byregion",
                        props:true,
                        beforeEnter: adminguard,
                        component: () => import("@/views/app/locations/districts/districts_list.vue"),
                        meta: {
                          title: 'Manage Countries in the Database'
                          }
                      },
                      {
                        path: "districts/:id",
                        name: "district_details",
                        props:true,
                        beforeEnter: adminguard,
                        component: () => import("@/views/app/locations/districts/district_details.vue"),
                        meta: {
                          title: 'Manage District in the Database'
                          }
                      },

                    ]
                },


                {
                  path: "dashboard",
                  name: "",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/locations/countries/main.vue"),
                    children:[
                      {
                        path: "subcounties",
                        name: "subcounties_list",
                        props:true,
                        beforeEnter: adminguard,
                        component: () => import("@/views/app/locations/subcounties/subcounties_list.vue"),
                        meta: {
                          title: 'Manage Countries in the Database'
                          }
                      },
                      {
                        path: "region/district/subcounties/:id/:title",
                        name: "subcounties_list_bydistrict",
                        props:true,
                        beforeEnter: adminguard,
                        component: () => import("@/views/app/locations/subcounties/subcounties_list.vue"),
                        meta: {
                          title: 'Manage Countries in the Database'
                          }
                      },
                      {
                        path: "subcounty/:id",
                        name: "subcounty_details",
                        props:true,
                        beforeEnter: adminguard,
                        component: () => import("@/views/app/locations/subcounties/subcounty_details.vue"),
                        meta: {
                          title: 'Manage District in the Database'
                          }
                      },

                    ]
                },
                
                {
                  path: "dashboard",
                  name: "",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/locations/countries/main.vue"),
                    children:[
                      {
                        path: "parishes",
                        name: "parishes_list",
                        props:true,
                        beforeEnter: adminguard,
                        component: () => import("@/views/app/locations/parishes/parishes_list.vue"),
                        meta: {
                          title: 'Manage Countries in the Database'
                          }
                      },
                      {
                        path: "region/district/subcounty/parishes/:id/:title",
                        name: "parishes_list_bysubcounty",
                        props:true,
                        beforeEnter: adminguard,
                        component: () => import("@/views/app/locations/parishes/parishes_list.vue"),
                        meta: {
                          title: 'Manage Countries in the Database'
                          }
                      },
                      {
                        path: "subcounty/:id",
                        name: "parish_details",
                        props:true,
                        beforeEnter: adminguard,
                        component: () => import("@/views/app/locations/parishes/parish_details.vue"),
                        meta: {
                          title: 'Manage District in the Database'
                          }
                      },

                    ]
                },



                {
                  path: "dashboard",
                  name: "",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/locations/countries/main.vue"),
                    children:[
                      {
                        path: "villages",
                        name: "villages_list",
                        props:true,
                        beforeEnter: adminguard,
                        component: () => import("@/views/app/locations/villages/villages_list.vue"),
                        meta: {
                          title: 'Manage Countries in the Database'
                          }
                      },
                      {
                        path: "region/district/subcounty/parish/villages/:id/:title",
                        name: "villages_list_byparish",
                        props:true,
                        beforeEnter: adminguard,
                        component: () => import("@/views/app/locations/villages/villages_list.vue"),
                        meta: {
                          title: 'Manage Countries in the Database'
                          }
                      },
                      {
                        path: "villages/:id",
                        name: "village_details",
                        props:true,
                        beforeEnter: adminguard,
                        component: () => import("@/views/app/locations/villages/village_details.vue"),
                        meta: {
                          title: 'Manage District in the Database'
                          }
                      },

                    ]
                },


              ]
            },


            {
              path: "/profile",
              name: "",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/profile/main.vue"),
              children: [
                {
                  path: "",
                  name: "user_profile",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/profile/settings.vue"),
                  meta: {
                  title: 'Manage Ongoing Orders and Create New ones'
                  }
                },
                {
                  path: "change-password",
                  name: "change-password",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/profile/change_password.vue"),
                  meta: {
                  title: 'Manage Ongoing Orders and Create New ones'
                  }
                },
              ]
            },





            
        ]
    }
]




const mergedRoutes =  routes;

export default mergedRoutes;
