export const namespaced = true
import store from '@/store'
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        parish :'',
        parishes : '',

    },
    
    getters : {
        parish: (state )=> state.parish,
        parishes: (state )=> state.parishes,

       
    },


    mutations : { 
        setparish :(state,parish ) => (state.parish = parish), 
        setparishes :(state,parishes ) => (state.parishes = parishes), 

    },

    actions : {





        async create_parish( { commit }, {data}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_parish' )
            return new Promise((resolve, reject) => {
            axios.post("/data/locations/parishes/create" , data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    commit('setparishes', response.data.data)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_parishes( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_parishes' )
            return new Promise((resolve, reject) => {
            axios.post("/data/locations/parishes?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    commit('setparishes', response.data.data)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_parish( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_parish' )
            return new Promise((resolve, reject) => {
            axios.post("/data/locations/parishes/update/" + id , data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    commit('setparishes', response.data.data)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async delete_parish( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_parish' )
            return new Promise((resolve, reject) => {
            axios.post("/data/locations/parishes/delete/" + id , data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    commit('setparishes', response.data.data)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    },

    
}













