export const namespaced = true
import store from '@/store'
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        district :'',
        districts : '',

    },
    
    getters : {
        district: (state )=> state.district,
        districts: (state )=> state.districts,

       
    },


    mutations : { 
        setdistrict :(state,district ) => (state.district = district), 
        setdistricts :(state,districts ) => (state.districts = districts), 

    },

    actions : {





        async create_district( { commit }, {data}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_district' )
            return new Promise((resolve, reject) => {
            axios.post("/data/locations/districts/create" , data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    commit('setdistricts', response.data.data)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_districts( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_districts' )
            return new Promise((resolve, reject) => {
            axios.post("/data/locations/districts?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    commit('setdistricts', response.data.data)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_district( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_district' )
            return new Promise((resolve, reject) => {
            axios.post("/data/locations/districts/update/" + id , data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    commit('setdistricts', response.data.data)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async delete_district( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_district' )
            return new Promise((resolve, reject) => {
            axios.post("/data/locations/districts/delete/" + id , data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    commit('setdistricts', response.data.data)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    },

    
}













