import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLs from 'secure-ls'
const secure = new SecureLs({isCompression:false});
import auth  from './modules/auth'
import utils  from './modules/utils'
import countries  from './modules/countries'
import regions  from './modules/regions'
import districts  from './modules/districts'
import subcounties  from './modules/subcounties'
import parishes  from './modules/parishes'
import villages  from './modules/villages'
import industries  from './modules/industries'
import professions  from './modules/professions'
import contacts  from './modules/contacts'
export default createStore({
  modules: {
    auth, 
    utils,
    countries,
    regions,
    districts,
    subcounties,
    parishes,
    villages,
    industries,
    professions,
    contacts

  },
    
  plugins: [createPersistedState(
    {
      key : 'smsadvantage_data_center_localstorage.com',
      paths: ['auth','utils'],
      storage:{
         getItem : (key) => secure.get(key),
         setItem: (key, value) => secure.set(key, value),
         removeItem: (key) => secure.remove(key)
      }
    }
  )],
  
})
