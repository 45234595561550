export const namespaced = true
import store from '@/store'
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        village :'',
        villages : '',

    },
    
    getters : {
        village: (state )=> state.village,
        villages: (state )=> state.villages,

       
    },


    mutations : { 
        setvillage :(state,village ) => (state.village = village), 
        setvillages :(state,villages ) => (state.villages = villages), 

    },

    actions : {





        async create_village( { commit }, {data}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_village' )
            return new Promise((resolve, reject) => {
            axios.post("/data/locations/villages/create" , data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    commit('setvillages', response.data.data)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_villages( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_villages' )
            return new Promise((resolve, reject) => {
            axios.post("/data/locations/villages?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    commit('setvillages', response.data.data)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_village( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_village' )
            return new Promise((resolve, reject) => {
            axios.post("/data/locations/villages/update/" + id , data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    commit('setvillages', response.data.data)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async delete_village( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_village' )
            return new Promise((resolve, reject) => {
            axios.post("/data/locations/villages/delete/" + id , data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    commit('setvillages', response.data.data)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    },

    
}













