export const namespaced = true
import store from '@/store'
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        contact :'',
        contacts : '',

    },
    
    getters : {
        contact: (state )=> state.contact,
        contacts: (state )=> state.contacts,

       
    },


    mutations : { 
        setcontact :(state,contact ) => (state.contact = contact), 
        setcontacts :(state,contacts ) => (state.contacts = contacts), 

    },

    actions : {

        async create_contact( { commit }, {data}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_contact' )
            return new Promise((resolve, reject) => {
            axios.post("/data/contacts/create" , data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    commit('setcontacts', response.data.data)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_contacts( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_contacts' )
            return new Promise((resolve, reject) => {
            axios.post("/data/contacts?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    commit('setcontacts', response.data.data)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_contact( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_contact' )
            return new Promise((resolve, reject) => {
            axios.post("/data/contacts/update/" + id , data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    commit('setcontacts', response.data.data)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async delete_contact( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_contact' )
            return new Promise((resolve, reject) => {
            axios.post("/data/contacts/delete/" + id , data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    commit('setcontacts', response.data.data)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },



        async change_contact_status( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_contact_status' + id )
            return new Promise((resolve, reject) => {
            axios.post("/data/contacts/status/" + id , data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    commit('setcontacts', response.data.data)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async validate_contact_phone( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'validate_contact_phone' + id )
            return new Promise((resolve, reject) => {
            axios.post("/data/contacts/verify/" + id , data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    commit('setcontacts', response.data.data)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        
    },

    
}













