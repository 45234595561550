export const namespaced = true 
export default {
    state : {
        error :'',
        errors :[],
        apierror:'',
        msg :'',
        loading:'',
        pageloading:'',
        logintypetype:'',
        uploadPercentage:0,
        nav_menu:'',
        careersdash:'',
        locationsdash:'',
        maindash:''
    },
    
    getters : {
        error: (state )=> state.error,
        errors: (state )=> state.errors,
        apierror: (state )=> state.apierror,
        msg: (state )=> state.msg,
        loading: (state )=> state.loading,
        pageloading: (state )=> state.pageloading,
        uploadPercentage: (state )=> state.uploadPercentage,
        nav_menu: (state )=> state.nav_menu,
        careersdash: (state )=> state.careersdash,
        locationsdash: (state )=> state.locationsdash,
        maindash: (state )=> state.maindash,
    },

    

    mutations : { 
        setloader :(state,loading ) => (state.loading = loading), 
        setpageloading :(state,pageloading ) => (state.pageloading = pageloading), 
        seterror :(state,error ) => (state.error = error), 
        seterrors :(state,errors ) => (state.errors = errors), 
        setmsg :(state,msg ) => (state.msg = msg), 
        setapierror :(state,apierror ) => (state.apierror = apierror),
        setuploadPercentage :(state,uploadPercentage ) => (state.uploadPercentage = uploadPercentage),
        setnav_menu :(state,nav_menu ) => (state.nav_menu = nav_menu),
        setcareersdash :(state,careersdash ) => (state.careersdash = careersdash),
        setlocationsdash:(state,locationsdash ) => (state.locationsdash = locationsdash),
        setmaindash:(state,maindash ) => (state.maindash = maindash),
    },


    actions : {

        async clearutils( { commit }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', '')
            commit('setapierror', '')
            commit('seterrors', '')
            commit('setpageloading', '')
        },


        async changenav_menu( { commit }, value) {
            commit('setnav_menu', value)
        },


        async get_main_dash_data( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_careers_dash_data')
            return new Promise((resolve, reject) => {
            axios.post("/data/dash", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    commit('setmaindash', response.data.data)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_careers_dash_data( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_careers_dash_data')
            return new Promise((resolve, reject) => {
            axios.post("/data/careers/dash", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    commit('setcareersdash', response.data.data)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_locations_dash_data( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_locations_dash_data')
            return new Promise((resolve, reject) => {
            axios.post("/data/locations/dash", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    commit('setlocationsdash', response.data.data)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },






    },

    
}