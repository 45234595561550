export const namespaced = true
import store from '@/store'
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        subcounty :'',
        subcounties : '',

    },
    
    getters : {
        subcounty: (state )=> state.subcounty,
        subcounties: (state )=> state.subcounties,

       
    },


    mutations : { 
        setsubcounty :(state,subcounty ) => (state.subcounty = subcounty), 
        setsubcounties :(state,subcounties ) => (state.subcounties = subcounties), 

    },

    actions : {





        async create_subcounty( { commit }, {data}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_subcounty' )
            return new Promise((resolve, reject) => {
            axios.post("/data/locations/subcounties/create" , data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    commit('setsubcounties', response.data.data)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_subcounties( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_subcounties' )
            return new Promise((resolve, reject) => {
            axios.post("/data/locations/subcounties?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    commit('setsubcounties', response.data.data)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_subcounty( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_subcounty' )
            return new Promise((resolve, reject) => {
            axios.post("/data/locations/subcounties/update/" + id , data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    commit('setsubcounties', response.data.data)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async delete_subcounty( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_subcounty' )
            return new Promise((resolve, reject) => {
            axios.post("/data/locations/subcounties/delete/" + id , data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    commit('setsubcounties', response.data.data)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    },

    
}













